.container {
  background-color: #3d3d3d;
  min-height: 100vh;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;

  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: center;
    flex-direction: column;
    grid-template-columns: auto;
  }

  &_item {
    background-color: rgba(95, 95, 95, 0.8);
    padding: 20px;
    margin: 20px;
    font-size: 30px;
    text-align: center;
    box-shadow: 0px 2px 4px #00000015;
    height: 300px;
    min-width: 500px;
    border-radius: 20px;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &_boardName {
      color: rgb(189, 255, 103);
      margin-top: 15px;
      line-height: 35px;
    }
    &_boardId {
      line-height: 35px;
    }

    &:hover {
      background-color: #9e9c9ca4;
    }

    @media screen and (max-width: 767px) {
      padding: 10px 50px;
      height: 200px;
      min-width: 100%;
      margin: 20px 0px;
    }
  }

}

.errorMessage {
  color: rgb(255, 123, 123);
  font-size: 28px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
}