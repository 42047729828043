/*---------------------------------
	BASE
-----------------------------------*/
* {
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #fff;
  font-size: 15px;
  line-height: 22px;
  background-color: #111111;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  outline: 0;
  text-decoration: none;
  color: #fff;
  box-shadow: none;
}

/*---------------------------------
	BREAK POINT MEDIA
-----------------------------------*/

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

/*---------------------------------
	UTILITY
-----------------------------------*/

ol,
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

a img {
  border: 0;
}

.cstm-row {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 15px;
}

/*---------------------------------*/

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

h1.h1-title {
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  position: relative;
  left: 0;
  top: 0;
  padding-bottom: 40px;
}

h1.h1-title:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #636363;
  height: 2px;
}

h1.h1-title:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  background-color: #2e73e2;
  height: 3px;
}

.main-body-wrapper {
  max-width: 1280px;
  margin: 20px auto 50px auto;
}

ul.tbl-holder {
  background-color: #272729;
  margin-top: 100px;
}

ul.tbl-holder li {
  border-bottom: 1px solid #636363;
  border-top: 1px solid #111111;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

ul.tbl-holder li:first-child {
  border-top: none;
}

ul.tbl-holder li.tbl-hdr {
  background-color: #111111;
  padding-bottom: 5px;
}

ul.tbl-holder li.tbl-hdr div.col_1:before {
  background: none;
}

ul.tbl-holder li.tbl-hdr div.col_1 {
  color: #669bf5;
  font-size: 18px;
}

ul.tbl-holder li div.col_1 {
  width: 40%;
  font-size: 15px;
  line-height: 22px;
  padding: 6px 45px 6px 15px;
  position: relative;
  left: 0;
  top: 0;
}

ul.tbl-holder li div.col_1:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #669bf5;
  height: 100%;
}

ul.tbl-holder li div.col_1 span.chat-icon {
  position: absolute;
  right: 10px;
  top: 3px;
  bottom: 0;
}

ul.tbl-holder li div.col_2 {
  width: 20%;
  font-size: 15px;
  line-height: 22px;
  padding: 6px 10px;
  text-align: center;
}

ul.tbl-holder li div.bg-color1 {
  background-color: #6ad096;
}

ul.tbl-holder li div.bg-color2 {
  background-color: #539dc9;
}

ul.tbl-holder li div.bg-color3 {
  background-color: #f08a62;
}

ul.tbl-holder li div.bg-color4 {
  background-color: #bb6276;
}
ul.tbl-holder li div.bg-color-default {
  background-color: #afa9aa;
}

ul.tbl-holder li div.col_3 {
  width: 14%;
  font-size: 15px;
  line-height: 22px;
  padding: 6px 10px;
  text-align: center;
}

ul.tbl-holder li div.col_3 span {
  display: block;
  background-color: #252a56;
  border-radius: 30px;
  padding: 2px 4px;
}

ul.tbl-holder li div.col_4 {
  width: 8%;
  font-size: 15px;
  line-height: 22px;
  padding: 6px 10px;
  text-align: center;
  border-left: 1px solid #111111;
}

ul.tbl-holder li div.col_5 {
  width: 8%;
  font-size: 15px;
  line-height: 22px;
  padding: 6px 10px;
  text-align: center;
  border-left: 1px solid #111111;
}

ul.tbl-holder li div.col_6 {
  width: 10%;
  font-size: 15px;
  line-height: 22px;
  padding: 6px 10px;
  text-align: center;
  border-left: 1px solid #111111;
}

ul.tbl-holder li div.col_1,
ul.tbl-holder li div.col_2,
ul.tbl-holder li div.col_3,
ul.tbl-holder li div.col_4,
ul.tbl-holder li div.col_5,
ul.tbl-holder li div.col_6 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

ul.tbl-holder li div.col_1 {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.chat-circle {
  width: 25px;
  height: 25px;
  border: 2px solid #2e73e2;
  padding: 0px 3px 4px 2px;
  text-align: center;
  font-weight: 400;
  font-size: 11px;
  color: #fff;
  border-radius: 50%;
  background-color: #2e73e2;
  display: inline-block;
}

.chat-circle:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #2e73e2;
  border-right: 10px solid transparent;
  border-top: 4px solid #2e73e2;
  border-bottom: 5px solid transparent;
  left: 2px;
  bottom: 8px;
}

.chat-console {
  border: 1px solid #636363;
  border-radius: 10px;
  padding: 20px;
  margin: 0 0 20px 0;
}

.chat-console-top {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.chat-console-top .profile-img {
  margin-right: 15px;
  width: 42px;
}

.chat-console-top .name {
  font-size: 17px;
  position: relative;
  left: 0;
  top: 0;
  padding-right: 15px;
}
.chat-console-top .name span.visible {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #5bc77a;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 6px;
}

.chat-console-body {
  padding-top: 20px;
}

@media (max-width: 992px) {
  .main-body-wrapper {
    padding: 0 15px;
  }
  .scroll-container {
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
  }

  ul.tbl-holder {
    white-space: nowrap;
    width: 1280px;
  }
}


.errorMessage {
  color: rgb(255, 123, 123);
  font-size: 28px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
}


.chatIcon {
  background-color: #555;
  color: white;
  text-decoration: none;
  padding: 15px 26px;
  /* position: relative; */
  display: inline-block;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  top: 3px;
  bottom: 0;
}

.chat-icon .badge {
  position: absolute;
  top: 2px;
  right: 0px;
  padding: 5px 10px;
  border-radius: 50%;
  /* background-color: rgb(8, 104, 107); */
  color: white;
}
