.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & img {
      width: 120px;
    }

    & h1 {
      color: #f15d5e;
      margin-bottom: 10px;
      font-weight: bold;
      margin-top: 5px;
    }

    & button {
      color: white;
      background-color: #30a1ec;
      background-size: 200px;
      border: none;
      background-position: 5% 95%;
      font-weight: 400;
      padding: 10px 30px;
      cursor: pointer;
      border-radius: 100px;
      font-size: 20px;
      text-transform: uppercase;
    }
    & button:active,
    button:focus {
      outline: none;
    }
  }
}

.notAllowedText {
  color: #d13636;
  font-size: 40px;
  text-align: center;
}
