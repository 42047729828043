.errorMessage {
  color: red;
  font-weight: 600;
}

.authContainer {
  display: flex;
  min-height: 500px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d3d3d;

  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  &_rightSide {
    box-sizing: border-box;
    flex: 0.3;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px #00000015;
    background-color: rgb(70, 69, 69);
    border-radius: 20px;
    padding: 50px 10px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      padding: 10px 60px;
      flex: 0.5;
    }

    & form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &__header {
      color: #dee2e9;
      font-size: 30px;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 40px;
      @media screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }

  &_submitBtn {
    color: white;
    background-color: #30a1ec;
    background-position: 0% 95%;
    background-size: 200%;
    border: none;
    min-width: 150px;
    padding: 15px 25px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  @media screen and (max-width: 700px) {
    align-items: center;
  }

  &_label {
    margin-bottom: 10px;
    color: #dee2e9;
    font-size: 15px;
  }

  &_input {
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    border: 1px solid #818181;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 18px;
    color: #e4e4e4;
    background-color: #434343;

    &:focus {
      outline: none;
      border: 1px solid #353535;
    }
  }

}
